<template>
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
        <div class="claim">
            <h1 class="mb-1">All Claims</h1>
            
            <!-- btn form repeater office -->
            <b-card v-if="!statusEdit">
                <b-row>
                    <b-col
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <div class="mx-1">
                            <h4 style="font-size: 18px;" class="mb-0 pb-0 mt-1">Filters</h4>
                            <b-row class="mt-1 d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="1" cols-sm="12" cols-md="4" cols-lg="4">
                                <b-col class="mb-25">
                                    <!-- <b-form-select
                                        v-model="clinicianName"
                                        inline
                                        :options="optionsClinicianName"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        class="d-inline-block mr-50"
                                    /> -->
                                    <v-select
                                        v-model="clinicianName"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        placeholder="Clinician Name"
                                        label="full"
                                        :dense="true"
                                        :options="optionsClinicianName"
                                    />
                                </b-col>
                                <b-col class="mb-25">
                                    <!-- <b-form-select
                                        v-model="clientName"
                                        inline
                                        :options="optionsClientName"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        class="d-inline-block mr-50"
                                    /> -->
                                    <v-select
                                        v-model="clientName"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        placeholder="Client Name"
                                        label="value"
                                        :dense="true"
                                        :options="optionsClientName"
                                    />
                                </b-col>
                                <b-col class="mb-25">
                                    <b-form-select
                                        v-model="claimStatus"
                                        inline
                                        :options="optionsClaimStatus"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        class="d-inline-block mr-50"
                                    />
                                </b-col>
                                <b-col class="mb-25">
                                    <b-form-select
                                        v-model="clinicianRegion"
                                        inline
                                        :options="optionsClinicianRegion"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        class="d-inline-block mr-50"
                                    />
                                </b-col>
                            </b-row>

                            <b-row class="mt-1 d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="1" cols-sm="12" cols-md="4" cols-lg="4">
                                <b-col class="mb-25" v-if="clinicianRegion === 'NYC'">
                                    <b-form-select
                                        v-model="clinicianBorough"
                                        inline
                                        :options="optionsBorough"
                                        placeholder="Borough"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        class="d-inline-block mr-50"
                                    />
                                </b-col>
                                <b-col class="mb-25">
                                    <b-form-select
                                        v-model="clinicianStatus"
                                        inline
                                        :options="optionsClinicianStatus"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        class="d-inline-block mr-50"
                                    />
                                </b-col>
                                <b-col class="mb-25">
                                    <flat-pickr
                                        v-model="startDate"
                                        class="d-inline-block mr-50"
                                        placeholder="Start Date"
                                        :config="{ altInput: true, altFormat: 'm-d-Y', dateFormat: 'd/m/Y',}"
                                    />
                                </b-col>
                                <b-col class="mb-25">
                                    <flat-pickr
                                        v-model="endDate"
                                        class="d-inline-block mr-50"
                                        placeholder="End Date"
                                        :config="{ altInput: true, altFormat: 'm-d-Y', dateFormat: 'd/m/Y',}"
                                    />
                                </b-col>
                                <b-col class="mb-25">
                                    <b-form-input
                                        v-model="clientID"
                                        class="d-inline-block"
                                        placeholder="Client ID"
                                    />
                                </b-col>
                                <b-col class="mb-25 mt-1">
                                    <b-form-select
                                        v-model="clinicianProgram"
                                        inline
                                        :options="optionsProgram"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        class="d-inline-block mr-50"
                                    />
                                </b-col>
                            </b-row>

                            <b-row class="my-1">
                                <b-col
                                    cols="12"
                                    md="6"
                                    v-if="activeFilters && !activeFiltersDate"
                                >
                                    <!-- <div class="text-primary" v-if="isBusy">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <span style="font-size: 14px; color: #000;"> results found</span>
                                    </div> -->
                                    <!-- <h2>{{totalRows.toLocaleString('en-US')}} <span style="font-size: 14px; color: #000;"> results found</span></h2> -->
                                    <b-spinner class="align-middle text-primary" v-if="apiLoaded"></b-spinner>  <span style="font-size: 14px; color: #000;" v-if="apiLoaded"> results found</span>
                                    <h2 v-else>{{activeFilters && !activeFiltersDate ? arrayFiltradoClaims.length : activeFilters && activeFiltersDate ? totalRowsQuery.toLocaleString('en-US') : totalRows.toLocaleString('en-US')}} <span style="font-size: 14px; color: #000;"> results found</span></h2>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="6"
                                    v-else-if="activeFilters && activeFiltersDate"
                                >
                                    <b-spinner class="align-middle text-primary" v-if="loadCounterQuery"></b-spinner>  <span style="font-size: 14px; color: #000;" v-if="loadCounterQuery"> results found</span>
                                    <h2 v-else>{{activeFilters && !activeFiltersDate ? arrayFiltradoClaims.length : activeFilters && activeFiltersDate ? totalRowsQuery.toLocaleString('en-US') : totalRows.toLocaleString('en-US')}} <span style="font-size: 14px; color: #000;"> results found</span></h2>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="6"
                                    v-else
                                >
                                    <b-spinner class="align-middle text-primary" v-if="loadCounterAll"></b-spinner>  <span style="font-size: 14px; color: #000;" v-if="loadCounterAll"> results found</span>
                                    <h2 v-else>{{activeFilters && !activeFiltersDate ? arrayFiltradoClaims.length : activeFilters && activeFiltersDate ? totalRowsQuery.toLocaleString('en-US') : totalRows.toLocaleString('en-US')}} <span style="font-size: 14px; color: #000;"> results found</span></h2>
                                </b-col>

                                <div
                                    v-if="claimStatus === 'pending dm approval' && isAdmin"
                                    class="mr-1 btn btn-default"
                                    :style="selected.length >= 1 ? 'background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 1; position: absolute; right: 0px;' : 'background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 0.5; position: absolute; right: 0px;'"
                                    @click="approveClaimsSelected()"
                                >
                                    Approve Claims
                                    <b-spinner small v-if="isBusy" class="ml-50" style="padding-bottom: 0px;" />
                                </div>
                                <div
                                    v-if="claimStatus === 'pending pm approval'"
                                    class="mr-1 btn btn-default"
                                    :style="selected.length >= 1 ? 'background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 1; position: absolute; right: 0px;' : 'background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 0.5; position: absolute; right: 0px;'"
                                    @click="approveClaimsSelected()"
                                >
                                    Pending DM Approval
                                    <b-spinner small v-if="isBusy" class="ml-50" style="padding-bottom: 0px;" />
                                </div>
                                <div
                                    v-if="claimStatus === 'approved' && isAdmin"
                                    class="mr-1 btn btn-default"
                                    :style="selected.length >= 1 ? 'background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 1; position: absolute; right: 0px;' : 'background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 0.5; position: absolute; right: 0px;'"
                                    @click="approveClaimsSelected()"
                                >
                                    Mark As Paid
                                    <b-spinner small v-if="isBusy" class="ml-50" style="padding-bottom: 0px;" />
                                </div>
                            </b-row>
                        </div>

                        <div v-if="activeFilters && !activeFiltersDate">
                            <b-table
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :items="arrayFiltradoClaims"
                                :fields="claimStatus && (claimStatus === 'pending dm approval' || claimStatus === 'pending pm approval' || claimStatus === 'approved') ? fieldsTableStatus : fieldsTable"
                                responsive
                                hover
                                :per-page="perPage"
                                :current-page="currentPage"
                                @row-clicked="onRowSelected"
                                @row-selected="onRowSelectedApproved"
                                :select-mode="'multi'"
                                ref="selectableTable"
                                selectable
                                :busy="apiLoaded"
                            >
                                <template #table-busy>
                                    <div class="text-center text-primary my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong class="ml-1">Loading...</strong>
                                    </div>
                                </template>

                                <template #head(selectable)="">
                                    <template>
                                        <b-form-checkbox
                                            v-model="selectedReset"
                                            :value="true"
                                            @change="selectedReset ? selectAllRows() : clearSelected()"
                                        >
                                        </b-form-checkbox>
                                    </template>
                                </template>

                                <template #cell(selectable)="data">
                                    <template v-if="data.item.approvalStatus === 'pending dm approval' || data.item.approvalStatus === 'pending pm approval' || data.item.approvalStatus === 'approved'">
                                        <b-form-checkbox
                                            v-model="data.item.rowSelected"
                                            :value="true"
                                            @change="observerCheckbox(data)"
                                        >
                                        </b-form-checkbox>
                                        {{functionPageSelected(data)}}
                                    </template>
                                </template>

                                <template #cell(claimDate)="data">
                                    <span>
                                        {{ data.item.claimDateFormat2 ? new Date(data.item.claimDateFormat2).toLocaleDateString("en-US", { year: 'numeric', month: 'long' }) : '' }}
                                    </span>
                                </template>

                                <template #cell(clientDetails)="data">
                                    <b>{{data.item.clientName}}</b><br>
                                    {{data.item.clientID && data.item.clientID.includes('#') ? data.item.clientID : '#'+data.item.clientID}}
                                </template>

                                <template #cell(clinicianDetails)="data">
                                    <b>{{data.item.clinicianName}}</b><br>
                                    <span>{{data.item.clinicianID}}</span>
                                </template>

                                <template #cell(approvalStatus)="data">
                                    <span style="text-transform: capitalize;">{{data.item.approvalStatus}}</span>
                                </template>

                                <template #cell(actions)="data">
                                    <b-button
                                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                        variant="outline-secondary"
                                        style="padding: 6px 8px; font-size: 12px; width: 100px;"
                                        @click="markAsPaidClaim(data.item.id)"
                                        v-if="data.item.approvalStatus === 'approved' && isAdmin"
                                    >
                                    Mark As Paid
                                    </b-button>
                                    <div class="demo-inline-spacing" v-if="(data.item.approvalStatus === 'pending dm approval' || data.item.approvalStatus ===  'pending pm approval') && (isAdmin)">
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block; margin-bottom: 5px;' : 'padding: 6px 8px; font-size: 11px; width: 65px;'"
                                            :class="data.item.approvalStatus ===  'pending pm approval' ? 'mt-0 mr-0' : 'mr-50 mt-0'"
                                            @click="markAsApproveClaim({id: data.item.id, type: data.item.approvalStatus ===  'pending pm approval' ? 'pending dm approval' : 'approved', act: data.item.approvalStatus})"
                                        >
                                        {{ data.item.approvalStatus ===  'pending pm approval' ? 'Pending DM Approval' : 'Approve' }}
                                        </b-button>
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block;' : 'padding: 6px 8px; font-size: 11px; width: 50px;'"
                                            class="mr-0 mt-0"
                                            @click="markAsDenyClaim({id: data.item.id, type: 'denied', act: data.item.approvalStatus})"
                                        >
                                        Deny
                                        </b-button>
                                    </div>
                                    <div class="demo-inline-spacing" v-else-if="data.item.approvalStatus ===  'pending pm approval' && adminOrManageUsers">
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block; margin-bottom: 5px;' : 'padding: 6px 8px; font-size: 11px; width: 65px;'"
                                            :class="data.item.approvalStatus ===  'pending pm approval' ? 'mt-0 mr-0' : 'mr-50 mt-0'"
                                            @click="markAsApproveClaim({id: data.item.id, type: data.item.approvalStatus ===  'pending pm approval' ? 'pending dm approval' : 'approved', act: data.item.approvalStatus})"
                                        >
                                        {{ data.item.approvalStatus ===  'pending pm approval' ? 'Pending DM Approval' : 'Approve' }}
                                        </b-button>
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block;' : 'padding: 6px 8px; font-size: 11px; width: 50px;'"
                                            class="mr-0 mt-0"
                                            @click="markAsDenyClaim({id: data.item.id, type: 'denied', act: data.item.approvalStatus})"
                                        >
                                        Deny
                                        </b-button>
                                    </div>
                                    <b-button
                                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                        variant="outline-secondary"
                                        style="padding: 6px 8px; font-size: 12px; width: 100px;"
                                        class="mt-25"
                                        @click="returnBackApprovalStatus({id: data.item.id, last: data.item.approvalStatus === 'pending dm approval' ? 'pending pm approval' : data.item.approvalStatus === 'approved' ? 'pending dm approval' : data.item.approvalStatus === 'denied' && !data.item.lastApprovalStatus ? 'pending pm approval' : data.item.approvalStatus === 'paid' ? 'approved' : data.item.lastApprovalStatus, type: data.item.approvalStatus})"
                                        v-if="(data.item.approvalStatus !== 'pending pm approval') && isAdmin"
                                    >
                                    Revert to {{data.item.approvalStatus === 'pending dm approval' ? 'pending pm approval' : data.item.approvalStatus === 'approved' ? 'pending dm approval' : data.item.approvalStatus === 'denied' && !data.item.lastApprovalStatus ? 'pending pm approval' : data.item.approvalStatus === 'paid' ? 'approved' : data.item.lastApprovalStatus}}
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                        <div v-else-if="activeFilters && activeFiltersDate">
                            <b-table
                                :items="itemsClaimsQuery"
                                :fields="claimStatus && (claimStatus === 'pending dm approval' || claimStatus === 'pending pm approval' || claimStatus === 'approved') ? fieldsTableStatus : fieldsTable"
                                responsive
                                hover
                                @row-clicked="onRowSelected"
                                @row-selected="onRowSelectedApproved"
                                :select-mode="'multi'"
                                ref="selectableTable"
                                selectable
                                :busy="apiLoaded"
                            >
                                <template #table-busy>
                                    <div class="text-center text-primary my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong class="ml-1">Loading...</strong>
                                    </div>
                                </template>

                                <template #head(selectable)="">
                                    <template>
                                        <b-form-checkbox
                                            v-model="selectedReset"
                                            :value="true"
                                            @change="selectedReset ? selectAllRows() : clearSelected()"
                                        >
                                        </b-form-checkbox>
                                    </template>
                                </template>

                                <template #cell(selectable)="data">
                                    <template v-if="data.item.approvalStatus === 'pending dm approval' || data.item.approvalStatus === 'pending pm approval' || data.item.approvalStatus === 'approved'">
                                        <b-form-checkbox
                                            v-model="data.item.rowSelected"
                                            :value="true"
                                            @change="observerCheckbox(data)"
                                        >
                                        </b-form-checkbox>
                                        {{functionPageSelected(data)}}
                                    </template>
                                </template>

                                <template #cell(claimDate)="data">
                                    <span>
                                        {{ data.item.claimDateFormat2 ? new Date(data.item.claimDateFormat2).toLocaleDateString("en-US", { year: 'numeric', month: 'long' }) : '' }}
                                    </span>
                                </template>

                                <template #cell(clientDetails)="data">
                                    <b>{{data.item.clientName}}</b><br>
                                    {{data.item.clientID && data.item.clientID.includes('#') ? data.item.clientID : '#'+data.item.clientID}}
                                </template>

                                <template #cell(clinicianDetails)="data">
                                    <b>{{data.item.clinicianName}}</b><br>
                                    <span>{{data.item.clinicianID}}</span>
                                </template>

                                <template #cell(approvalStatus)="data">
                                    <span style="text-transform: capitalize;">{{data.item.approvalStatus}}</span>
                                </template>

                                <template #cell(actions)="data">
                                    <b-button
                                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                        variant="outline-secondary"
                                        style="padding: 6px 8px; font-size: 12px; width: 100px;"
                                        @click="markAsPaidClaim(data.item.id)"
                                        v-if="data.item.approvalStatus === 'approved' && isAdmin"
                                    >
                                    Mark As Paid
                                    </b-button>
                                    <div class="demo-inline-spacing" v-if="(data.item.approvalStatus === 'pending dm approval') && (isAdmin)">
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block; margin-bottom: 5px;' : 'padding: 6px 8px; font-size: 11px; width: 65px;'"
                                            :class="data.item.approvalStatus ===  'pending pm approval' ? 'mt-0 mr-0' : 'mr-50 mt-0'"
                                            @click="markAsApproveClaim({id: data.item.id, type: 'approved', act: data.item.approvalStatus})"
                                        >
                                        {{ data.item.approvalStatus ===  'pending pm approval' ? 'Pending DM Approval' : 'Approve' }}
                                        </b-button>
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block;' : 'padding: 6px 8px; font-size: 11px; width: 50px;'"
                                            class="mr-0 mt-0"
                                            @click="markAsDenyClaim({id: data.item.id, type: 'denied', act: data.item.approvalStatus})"
                                        >
                                        Deny
                                        </b-button>
                                    </div>
                                    <div class="demo-inline-spacing" v-else-if="data.item.approvalStatus ===  'pending pm approval' && adminOrManageUsers">
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block; margin-bottom: 5px;' : 'padding: 6px 8px; font-size: 11px; width: 65px;'"
                                            :class="data.item.approvalStatus ===  'pending pm approval' ? 'mt-0 mr-0' : 'mr-50 mt-0'"
                                            @click="markAsApproveClaim({id: data.item.id, type: 'pending dm approval', act: data.item.approvalStatus})"
                                        >
                                        {{ data.item.approvalStatus ===  'pending pm approval' ? 'Pending DM Approval' : 'Approve' }}
                                        </b-button>
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block;' : 'padding: 6px 8px; font-size: 11px; width: 50px;'"
                                            class="mr-0 mt-0"
                                            @click="markAsDenyClaim({id: data.item.id, type: 'denied', act: data.item.approvalStatus})"
                                        >
                                        Deny
                                        </b-button>
                                    </div>
                                    <b-button
                                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                        variant="outline-secondary"
                                        style="padding: 6px 8px; font-size: 12px; width: 100px;"
                                        class="mt-25"
                                        @click="returnBackApprovalStatus({id: data.item.id, last: data.item.approvalStatus === 'pending dm approval' ? 'pending pm approval' : data.item.approvalStatus === 'approved' ? 'pending dm approval' : data.item.approvalStatus === 'denied' && !data.item.lastApprovalStatus ? 'pending pm approval' : data.item.approvalStatus === 'paid' ? 'approved' : data.item.lastApprovalStatus, type: data.item.approvalStatus})"
                                        v-if="(data.item.approvalStatus !== 'pending pm approval') && isAdmin"
                                    >
                                    Revert to {{data.item.approvalStatus === 'pending dm approval' ? 'pending pm approval' : data.item.approvalStatus === 'approved' ? 'pending dm approval' : data.item.approvalStatus === 'denied' && !data.item.lastApprovalStatus ? 'pending pm approval' : data.item.approvalStatus === 'paid' ? 'approved' : data.item.lastApprovalStatus}}
                                    </b-button>
                                </template>
                            </b-table>
                            <div class="text-center text-primary my-2" v-if="loadingInfiniteScroll && !isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong class="ml-1">Loading...</strong>
                            </div>
                            <div class="text-center text-primary my-2" v-if="totalRowsQuery - itemsClaimsQuery.length >= 1">
                                <b-button
                                    variant="outline-primary"
                                    class="mt-1"
                                    @click="loadMoreClaimsFilters"
                                >
                                Load more
                                </b-button>
                            </div>
                        </div>
                        <div v-else-if="!activeFilters">
                            <b-table
                                :items="itemsClaims"
                                :fields="claimStatus && (claimStatus === 'pending dm approval' || claimStatus === 'pending pm approval' || claimStatus === 'approved') ? fieldsTableStatus : fieldsTable"
                                responsive
                                hover
                                @row-clicked="onRowSelected"
                                @row-selected="onRowSelectedApproved"
                                :select-mode="'multi'"
                                ref="selectableTable"
                                selectable
                                :busy="isBusy"
                            >
                                <template #table-busy>
                                    <div class="text-center text-primary my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong class="ml-1">Loading...</strong>
                                    </div>
                                </template>

                                <template #head(selectable)="">
                                    <template>
                                        <b-form-checkbox
                                            v-model="selectedReset"
                                            :value="true"
                                            @change="selectedReset ? selectAllRows() : clearSelected()"
                                        >
                                        </b-form-checkbox>
                                    </template>
                                </template>

                                <template #cell(selectable)="data">
                                    <template v-if="data.item.approvalStatus === 'pending dm approval' || data.item.approvalStatus === 'pending pm approval' || data.item.approvalStatus === 'approved'">
                                        <b-form-checkbox
                                            v-model="data.item.rowSelected"
                                            :value="true"
                                            @change="observerCheckbox(data)"
                                        >
                                        </b-form-checkbox>
                                        {{functionPageSelected(data)}}
                                    </template>
                                </template>

                                <template #cell(claimDate)="data">
                                    <span>
                                        {{ data.item.claimDateFormat2 ? new Date(data.item.claimDateFormat2).toLocaleDateString("en-US", { year: 'numeric', month: 'long' }) : '' }}
                                    </span>
                                </template>

                                <template #cell(clientDetails)="data">
                                    <b>{{data.item.clientName}}</b><br>
                                    {{data.item.clientID && data.item.clientID.includes('#') ? data.item.clientID : '#'+data.item.clientID}}
                                </template>

                                <template #cell(clinicianDetails)="data">
                                    <b>{{data.item.clinicianName}}</b><br>
                                    <span>{{data.item.clinicianID}}</span>
                                </template>

                                <template #cell(approvalStatus)="data">
                                    <span style="text-transform: capitalize;">{{data.item.approvalStatus}}</span>
                                </template>

                                <template #cell(actions)="data">
                                    <b-button
                                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                        variant="outline-secondary"
                                        style="padding: 6px 8px; font-size: 12px; width: 100px;"
                                        @click="markAsPaidClaim(data.item.id)"
                                        v-if="data.item.approvalStatus === 'approved' && isAdmin"
                                    >
                                    Mark As Paid
                                    </b-button>
                                    <div class="demo-inline-spacing" v-if="(data.item.approvalStatus === 'pending dm approval') && (dataSpecialist || isAdmin)">
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block; margin-bottom: 5px;' : 'padding: 6px 8px; font-size: 11px; width: 65px;'"
                                            :class="data.item.approvalStatus ===  'pending pm approval' ? 'mt-0 mr-0' : 'mr-50 mt-0'"
                                            @click="markAsApproveClaim({id: data.item.id, type: 'approved', act: data.item.approvalStatus})"
                                        >
                                        {{ data.item.approvalStatus ===  'pending pm approval' ? 'Pending DM Approval' : 'Approve' }}
                                        </b-button>
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block;' : 'padding: 6px 8px; font-size: 11px; width: 50px;'"
                                            class="mr-0 mt-0"
                                            @click="markAsDenyClaim({id: data.item.id, type: 'denied', act: data.item.approvalStatus})"
                                        >
                                        Deny
                                        </b-button>
                                    </div>
                                    <div class="demo-inline-spacing" v-else-if="data.item.approvalStatus ===  'pending pm approval' && adminOrManageUsers">
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block; margin-bottom: 5px;' : 'padding: 6px 8px; font-size: 11px; width: 65px;'"
                                            :class="data.item.approvalStatus ===  'pending pm approval' ? 'mt-0 mr-0' : 'mr-50 mt-0'"
                                            @click="markAsApproveClaim({id: data.item.id, type: 'pending dm approval', act: data.item.approvalStatus})"
                                        >
                                        {{ data.item.approvalStatus ===  'pending pm approval' ? 'Pending DM Approval' : 'Approve' }}
                                        </b-button>
                                        <b-button
                                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                            variant="outline-secondary"
                                            :style="data.item.approvalStatus ===  'pending pm approval' ? 'padding: 6px 8px; font-size: 11px; width: 100%; display: block;' : 'padding: 6px 8px; font-size: 11px; width: 50px;'"
                                            class="mr-0 mt-0"
                                            @click="markAsDenyClaim({id: data.item.id, type: 'denied', act: data.item.approvalStatus})"
                                        >
                                        Deny
                                        </b-button>
                                    </div>
                                    <b-button
                                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                        variant="outline-secondary"
                                        style="padding: 6px 8px; font-size: 12px; width: 100px;"
                                        class="mt-25"
                                        @click="returnBackApprovalStatus({id: data.item.id, last: data.item.approvalStatus === 'pending dm approval' ? 'pending pm approval' : data.item.approvalStatus === 'approved' ? 'pending dm approval' : data.item.approvalStatus === 'denied' && !data.item.lastApprovalStatus ? 'pending pm approval' : data.item.approvalStatus === 'paid' ? 'approved' : data.item.lastApprovalStatus, type: data.item.approvalStatus})"
                                        v-if="(data.item.approvalStatus !== 'pending pm approval') && isAdmin"
                                    >
                                    Revert to {{data.item.approvalStatus === 'pending dm approval' ? 'pending pm approval' : data.item.approvalStatus === 'approved' ? 'pending dm approval' : data.item.approvalStatus === 'denied' && !data.item.lastApprovalStatus ? 'pending pm approval' : data.item.approvalStatus === 'paid' ? 'approved' : data.item.lastApprovalStatus}}
                                    </b-button>
                                </template>
                            </b-table>
                            <div class="text-center text-primary my-2" v-if="loadingInfiniteScroll && !isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong class="ml-1">Loading...</strong>
                            </div>
                            <div class="text-center text-primary my-2" v-if="totalRows - itemsClaims.length >= 1">
                                <b-button
                                    variant="outline-primary"
                                    class="mt-1"
                                    @click="loadMoreClaims"
                                >
                                Load more
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            
            </b-card>

            <b-card v-else>
                <edit-client-claim :idClientClaim="idClientClaim" @statusEditClientClaim="statusEdit = $event"/>
            </b-card>
        </div>
    </b-overlay>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BCard,
    BFormSelect, VBTooltip, BPagination, BOverlay, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EditClientClaim from '../user/users-edit/components/EditClientClaim.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // view edit
            statusEdit: false,
            idClientClaim: '',
            adminOrManageUsers: false,
            isAdmin: false,
            dataSpecialist: false,
            clinicianOrClinicianViewer: false,
            isClinician: false,
            userUID: '',
            clinicianProgram: null,
            optionsProgram: [],
            json_data: [],
            selected: [],
            readyToExport: true,
            selectedReset: false,

            // settings table and edit view
            itemsClaims: [],
            itemsClaimsQuery: [],
            fieldsTable: [
                // { key: 'selectable', label: '', sortable: false, thClass: 'thClaimsSelectable', tdClass: 'tdClaimsSelectable' },
                { key: 'clientDetails', sortable: false },
                { key: 'clinicianDetails', sortable: false },
                { key: 'approvalStatus', sortable: false },
                { key: 'claimDate', sortable: false },
                { key: 'actions', label: '', sortable: false, thClass: 'thClaimsActions', tdClass: 'tdClaimsActions' },
            ],
            fieldsTableStatus: [
                { key: 'selectable', label: '', sortable: false, thClass: 'thClaimsSelectable', tdClass: 'tdClaimsSelectable' },
                { key: 'clientDetails', sortable: false },
                { key: 'clinicianDetails', sortable: false },
                { key: 'approvalStatus', sortable: false },
                { key: 'claimDate', sortable: false },
                { key: 'actions', label: '', sortable: false, thClass: 'thClaimsActions', tdClass: 'tdClaimsActions' },
            ],
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [3, 5, 10, 25, 50, 100, 200],
            sortBy: 'claimDateFormat2',
            sortDesc: true,

            // filter
            clinicianName: null,
            optionsClinicianName: [],
            clientName: null,
            optionsClientName: [],
            clinicianRegion: null,
            optionsClinicianRegion: [],
            clinicianBorough: null,
            optionsBorough: [
                {value: null, text: 'Any Borough'},
                {value: 'Bronx', text: 'Bronx'},
                {value: 'Brooklyn', text: 'Brooklyn'},
                {value: 'Manhattan', text: 'Manhattan'},
                {value: 'Queens', text: 'Queens'},
                {value: 'Staten Island', text: 'Staten Island'},
            ],
            claimStatus: null,
            optionsClaimStatus: [
                {value: null, text: 'Any claim status'},
                {value: 'paid', text: 'Paid'},
                {value: 'approved', text: 'Approved'},
                {value: 'denied', text: 'Denied'},
                {value: 'pending dm approval', text: 'Pending DM Approval'},
                {value: 'pending pm approval', text: 'Pending PM Approval'},
            ],
            clinicianStatus: null,
            optionsClinicianStatus: [
                {value: null, text: 'Any status'},
                {value: 'active', text: 'Active Clinicians'},
                {value: 'inactive', text: 'Inactive Clinicians'},
            ],
            startDate: null,
            endDate: null,
            clientID: null,
            fixedClientID: null,

            // table clinician resume
            cliniciansResume: [],
            fieldsTableClinicianResume: [
                { key: 'clinician', sortable: false, tdClass: 'tdClinicianResumeName' },
                { key: 'noClaims', label: '# Claims', sortable: false, thClass: 'thClinicianNoClaims', tdClass: 'tdClinicianNoClaims' },
                { key: 'total', sortable: false, thClass: 'thClaimsApprovedTotal', tdClass: 'tdClaimsApprovedTotal'  },
                { key: 'actions', label: '', sortable: false, tdClass: 'tdClaimsApprovedActions' },
            ],

            // overlay
            variant: 'light',
            opacity: 0.85,
            blur: '2px',
            isBusy: true,
            latestDoc: null,
            latestDocQuery: null,
            latestDocApprv: null,
            latestDocClient: null,
            latestDocClinician: null,
            loadingInfiniteScroll: false,
            apiLoaded: true,
            activeFilters: false,
            activeFiltersDate: false,
            isBusyResumeApproved: true,
            isCompleteData: false,
            showOverlay: false,

            // new vars query claims
            totalRowsQuery: 0,
            loadCounterQuery: false,
            loadCounterAll: true,
            clientUID: '',
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BCard,
        BFormSelect,
        BPagination,
        BOverlay,
        BSpinner,
        BFormCheckbox,
        Cleave,
        
        // select
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,

        // edit claim
        EditClientClaim,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    computed: {
        ...mapGetters(['arrayFiltradoClaims']),
        ...mapState(['searchClaimClinicianNameState','searchClaimNameState','searchClaimStatusState','searchClaimClinicianRegionState','searchClaimClinicianStatusState','searchClaimStartDateState','searchClaimEndDateState','searchClaimIDState','searchClaimClinicianProgramState'])
    },
    watch: {
        clinicianName(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            if(val) {
                if(val.value === 'All Clinicians') {
                    this.clinicianName = null
                    this.apiLoaded = false
                    this.loadCounterQuery = false
                    this.checkActiveFilters()
                } else {
                    this.checkActiveFilters()
                    this.searchClaimClinicianName(val)
                    val.value ? this.searchClaimClinicianName(val) : this.searchClaimClinicianName(val)
                }
            } else {
                this.apiLoaded = false
                this.loadCounterQuery = false
                this.checkActiveFilters()
                this.searchClaimClinicianName('')
            }
            // this.searchClaimClinicianName(val)
        },
        clientName(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            // if(val) {
            //     this.activeFilters = true
            //     this.searchClaimClientName(val.value)
            // } else {
            //     this.searchClaimClientName('')
            // }
            // console.log(val)
            this.clientUID = ''
            if(val) {
                if(val.value === 'All Clients') {
                    this.clientName = null
                    this.apiLoaded = true
                    this.loadCounterQuery = false
                    this.clientID = null
                    this.checkActiveFilters()
                } else {
                    this.clientUID = val.uid ? val.uid : ''
                    this.checkActiveFilters()
                    // this.clientID = val.codigo.includes('#') ? val.codigo : '#'+val.codigo
                    this.searchClaimClientName(val.value)
                }
            } else {
                this.apiLoaded = false
                this.loadCounterQuery = false
                this.clientID = null
                this.searchClaimClientName('')
                this.checkActiveFilters()
            }
            // this.searchClaimClientName(val)
        },
        claimStatus(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            this.checkActiveFilters()
            this.searchClaimClaimStatus(val)
        },
        clinicianRegion(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            this.checkActiveFilters()
            this.clinicianBorough = this.clinicianRegion === 'NYC' && this.clinicianBorough ? this.clinicianBorough : null
            this.searchClaimClinicianRegion(val)
        },
        clinicianBorough(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            this.checkActiveFilters()
            // console.log(val)
            if(val) {
                this.searchClaimClinicianRegion(val)
            } else {
                if(this.clinicianRegion) {
                    this.searchClaimClinicianRegion(this.clinicianRegion)
                } else {
                    this.searchClaimClinicianRegion(val)
                }
            }
        },
        clinicianStatus(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            this.checkActiveFilters()
            this.searchClaimClinicianStatus(val)
        },
        startDate(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            this.checkActiveFilters()
            this.searchClaimStartDate(val)
        },
        endDate(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            this.checkActiveFilters()
            this.searchClaimEndDate(val)
        },
        clientID(val) {
            // var valFixed = val ? val.includes('#') ? val.replace(/^(0+)/g, '') : '#'+val.replace(/^(0+)/g, '') : ''
            var valFixed = val ? val.includes('#') ? val : '#'+val : ''
            if(this.fixedClientID !== valFixed) {
                // this.fixedClientID = val ? val.includes('#') ? val.replace(/^(0+)/g, '') : '#'+val.replace(/^(0+)/g, '') : ''
                this.fixedClientID = val ? val.includes('#') ? val : '#'+val : ''
                this.perPage = 10
                val ? this.perPage += 20 : this.perPage = 10
                this.apiLoaded = true
                this.loadCounterQuery = true
                this.latestDocQuery = null
                this.checkActiveFilters()
                // console.log(val.replace(/^(0+)/g, ''))
                val ? this.searchClaimsClientID(val.includes('#') ? val : '#'+val) : this.searchClaimsClientID('')
            }
        },
        clinicianProgram(val) {
            this.perPage = 10
            val ? this.perPage += 20 : this.perPage = 10
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.latestDocQuery = null
            this.checkActiveFilters()
            this.searchClaimClinicianProgram(val)
        },
        activeFilters(val) {
            // console.log(val)
            this.clearSelected()
            if(val) {
                this.perPage += 30
            } else {
                this.perPage = 10
            }
            // console.log(this.perPage)
        },
        perPage(val) {
            if(this.activeFilters) this.loadingInfiniteScroll = false
        },
        arrayFiltradoClaims(val) {
            if(this.isCompleteData) {
                this.apiLoaded = false
            }
            // this.totalRows = val.length

            if(!this.readyToExport) {
                this.ckeckExporter()
            }
        }
    },
    methods: {
        ...mapActions(['searchClaimsClientID','searchClaimClinicianName','searchClaimClientName','searchClaimClaimStatus','searchClaimClinicianRegion','searchClaimClinicianStatus','searchClaimStartDate','searchClaimEndDate','searchClaimClinicianProgram','setSnapshotClaims','getClaims']),
        ckeckExporter() {
            if(this.isCompleteData) {
                if(!this.activeFilters) {
                    this.readyToExport = false
                    this.json_data = []
                    // this.arrayFiltradoClaims.forEach(ele => {
                    //     if(this.json_data.filter(item => item.id === ele.id).length === 0) {
                    //         var detailsClient = (ele.clientName ? ele.clientName : '') + ' - ' + (ele.clientID ? ele.clientID : '')
                    //         this.json_data.push({
                    //             clinicianName: ele.clinicianName ? ele.clinicianName : '',
                    //             clientDetails: detailsClient,
                    //             claimDate: ele.claimDate,
                    //             claimAmount: ele.amountBilledToCFE ? ele.amountBilledToCFE : '',
                    //         })
                    //     }
                    // })
                    this.readyToExport = true
                } else if(this.activeFilters && !this.activeFiltersDate) {
                    this.readyToExport = false
                    this.json_data = []
                    // this.arrayFiltradoClaims.forEach(ele => {
                    //     if(this.json_data.filter(item => item.id === ele.id).length === 0) {
                    //         var detailsClient = (ele.clientName ? ele.clientName : '') + ' - ' + (ele.clientID ? ele.clientID : '')
                    //         this.json_data.push({
                    //             clinicianName: ele.clinicianName ? ele.clinicianName : '',
                    //             clientDetails: detailsClient,
                    //             claimDate: ele.claimDate,
                    //             claimAmount: ele.amountBilledToCFE ? ele.amountBilledToCFE : '',
                    //         })
                    //     }
                    // })
                    this.readyToExport = true
                }
            } else {
                this.readyToExport = false
            }
        },
        async queryClaims() {
            // this.startDate && this.endDate ? this.activeFiltersDate = true : this.activeFiltersDate = false
            this.clearSelected()
            this.activeFiltersDate = true
            !this.latestDocQuery ? this.itemsClaimsQuery = [] : ''
            this.clinicianBorough = this.clinicianBorough === 'bronx' ? 'Bronx' : this.clinicianBorough === 'brooklyn' ? 'Brooklyn' : this.clinicianBorough === 'manhattan' ? 'Manhattan' : this.clinicianBorough === 'queens' ? 'Queens' : this.clinicianBorough === 'staten island' ? 'Staten Island' : this.clinicianBorough
            // console.log(this.clientID)

            if(this.startDate && this.endDate) {
                var dateFrom = this.startDate.split("/")
                var dateTo = this.endDate.split("/")
                // var dateCheck = claimDateFormat.split("/")
                var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
                from.setUTCHours(0)
                from.setUTCMinutes(0)
                from.setUTCSeconds(0)
                from.setUTCMilliseconds(0)

                var to = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
                to.setUTCHours(0)
                to.setUTCMinutes(0)
                to.setUTCSeconds(0)
                to.setUTCMilliseconds(0)
                // .where('statusClinician', '==', this.clinicianStatus)
                // .where('regionBorough', '==', this.clinicianBorough)
                // borough: this.clinicianBorough,
                // if(this.clinicianBorough) {

                if(!this.clinicianName && !this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'date',
                            data: 'q1',
                            fromY: dateFrom[2],
                            fromM: parseInt(dateFrom[1])-1,
                            fromD: dateFrom[0],
                            toY: dateTo[2],
                            toM: parseInt(dateTo[1])-1,
                            toD: dateTo[0],
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '==', 'active').where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()

                    // ouputs docs
                    // .then(docsClients => {
                    data.docs.forEach(dataClientClaim => {
                        var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                        // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                        if(filterClaim.length === 0) {
                            var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                            var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                            this.itemsClaimsQuery.push({
                                id: dataClientClaim.id,
                                status: dataClientClaim.data().status,
                                clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                approvalStatus: dataClientClaim.data().approvalStatus,
                                lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                amountBilledToCFE: amountBilledToCFE,
                                region: dataClientClaim.data().region,
                                regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                            })
                        }
                    })
                    // console.log(this.itemsClaimsQuery.length)
                    if(!data.empty) {
                        this.latestDocQuery = data.docs[data.docs.length - 1]
                    }
                    this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                    this.apiLoaded = false
                    this.loadingInfiniteScroll = false
                    this.isBusy = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clientName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'date',
                                data: 'q11',
                                fromY: dateFrom[2],
                                fromM: parseInt(dateFrom[1])-1,
                                fromD: dateFrom[0],
                                toY: dateTo[2],
                                toM: parseInt(dateTo[1])-1,
                                toD: dateTo[0],
                                borough: this.clinicianBorough
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('regionBorough', '==', this.clinicianBorough).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'date',
                                data: 'q2',
                                fromY: dateFrom[2],
                                fromM: parseInt(dateFrom[1])-1,
                                fromD: dateFrom[0],
                                toY: dateTo[2],
                                toM: parseInt(dateTo[1])-1,
                                toD: dateTo[0],
                                region: this.clinicianRegion
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('region', '==', this.clinicianRegion).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(this.clinicianName && !this.clientName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'date',
                                data: 'q12',
                                fromY: dateFrom[2],
                                fromM: parseInt(dateFrom[1])-1,
                                fromD: dateFrom[0],
                                toY: dateTo[2],
                                toM: parseInt(dateTo[1])-1,
                                toD: dateTo[0],
                                borough: this.clinicianBorough,
                                clinician: this.clinicianName.codigo
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        // var ref = db.collection('clientsClaims').where('status', '==', 'active').where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('regionBorough', '==', this.clinicianBorough).where('clinicianID', '==', this.clinicianName.codigo).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'date',
                                data: 'q3',
                                fromY: dateFrom[2],
                                fromM: parseInt(dateFrom[1])-1,
                                fromD: dateFrom[0],
                                toY: dateTo[2],
                                toM: parseInt(dateTo[1])-1,
                                toD: dateTo[0],
                                region: this.clinicianRegion,
                                clinician: this.clinicianName.codigo
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        // var ref = db.collection('clientsClaims').where('status', '==', 'active').where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('region', '==', this.clinicianRegion).where('clinicianID', '==', this.clinicianName.codigo).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && this.clientName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'date',
                                data: 'q13',
                                fromY: dateFrom[2],
                                fromM: parseInt(dateFrom[1])-1,
                                fromD: dateFrom[0],
                                toY: dateTo[2],
                                toM: parseInt(dateTo[1])-1,
                                toD: dateTo[0],
                                borough: this.clinicianBorough,
                                clientUID: this.clientUID
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('regionBorough', '==', this.clinicianBorough).where('client', '==', this.clientUID).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'date',
                                data: 'q4',
                                fromY: dateFrom[2],
                                fromM: parseInt(dateFrom[1])-1,
                                fromD: dateFrom[0],
                                toY: dateTo[2],
                                toM: parseInt(dateTo[1])-1,
                                toD: dateTo[0],
                                region: this.clinicianRegion,
                                clientUID: this.clientUID
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('region', '==', this.clinicianRegion).where('client', '==', this.clientUID).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && !this.clientName && this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'date',
                                data: 'q15',
                                fromY: dateFrom[2],
                                fromM: parseInt(dateFrom[1])-1,
                                fromD: dateFrom[0],
                                toY: dateTo[2],
                                toM: parseInt(dateTo[1])-1,
                                toD: dateTo[0],
                                borough: this.clinicianBorough,
                                approvalStatus: this.claimStatus
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('regionBorough', '==', this.clinicianBorough).where('approvalStatus', '==', this.claimStatus).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'date',
                                data: 'q6',
                                fromY: dateFrom[2],
                                fromM: parseInt(dateFrom[1])-1,
                                fromD: dateFrom[0],
                                toY: dateTo[2],
                                toM: parseInt(dateTo[1])-1,
                                toD: dateTo[0],
                                region: this.clinicianRegion,
                                approvalStatus: this.claimStatus
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('region', '==', this.clinicianRegion).where('approvalStatus', '==', this.claimStatus).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && !this.clientName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'date',
                                data: 'q17',
                                fromY: dateFrom[2],
                                fromM: parseInt(dateFrom[1])-1,
                                fromD: dateFrom[0],
                                toY: dateTo[2],
                                toM: parseInt(dateTo[1])-1,
                                toD: dateTo[0],
                                borough: this.clinicianBorough,
                                clinicianProgram: this.clinicianProgram
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('regionBorough', '==', this.clinicianBorough).where('clinicianProgram', '==', this.clinicianProgram).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'date',
                                data: 'q18',
                                fromY: dateFrom[2],
                                fromM: parseInt(dateFrom[1])-1,
                                fromD: dateFrom[0],
                                toY: dateTo[2],
                                toM: parseInt(dateTo[1])-1,
                                toD: dateTo[0],
                                region: this.clinicianRegion,
                                clinicianProgram: this.clinicianProgram
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('region', '==', this.clinicianRegion).where('clinicianProgram', '==', this.clinicianProgram).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(this.clinicianName && !this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'date',
                            data: 'q7',
                            fromY: dateFrom[2],
                            fromM: parseInt(dateFrom[1])-1,
                            fromD: dateFrom[0],
                            toY: dateTo[2],
                            toM: parseInt(dateTo[1])-1,
                            toD: dateTo[0],
                            clinician: this.clinicianName.codigo
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '==', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'date',
                            data: 'q8',
                            fromY: dateFrom[2],
                            fromM: parseInt(dateFrom[1])-1,
                            fromD: dateFrom[0],
                            toY: dateTo[2],
                            toM: parseInt(dateTo[1])-1,
                            toD: dateTo[0],
                            clientUID: this.clientUID
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '==', 'active').where('clientName', '==', this.clientName).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clientName && !this.claimStatus && !this.clinicianRegion && this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'date',
                            data: 'q9',
                            fromY: dateFrom[2],
                            fromM: parseInt(dateFrom[1])-1,
                            fromD: dateFrom[0],
                            toY: dateTo[2],
                            toM: parseInt(dateTo[1])-1,
                            toD: dateTo[0],
                            statusClinician: this.clinicianStatus
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '==', 'active').where('statusClinician', '==', this.clinicianStatus).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clientName && this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'date',
                            data: 'q10',
                            fromY: dateFrom[2],
                            fromM: parseInt(dateFrom[1])-1,
                            fromD: dateFrom[0],
                            toY: dateTo[2],
                            toM: parseInt(dateTo[1])-1,
                            toD: dateTo[0],
                            approvalStatus: this.claimStatus
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '==', 'active').where('approvalStatus', '==', this.claimStatus).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'date',
                            data: 'q16',
                            fromY: dateFrom[2],
                            fromM: parseInt(dateFrom[1])-1,
                            fromD: dateFrom[0],
                            toY: dateTo[2],
                            toM: parseInt(dateTo[1])-1,
                            toD: dateTo[0],
                            clinicianProgram: this.clinicianProgram
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '==', 'active').where('clinicianProgram', '==', this.clinicianProgram).where('claimStamp', '>=', from).where('claimStamp', '<=', to).orderBy('claimStamp').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clinicianName && this.clinicianName.codigo) {
                // QUERY CLINICIAN NAME
                if(!this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'clinicianName',
                            data: 'q1',
                            clinicianID: this.clinicianName.codigo
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clientName && this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clientName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('regionBorough', '==', this.clinicianBorough).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                this.totalRowsQuery = this.itemsClaimsQuery.length
                                this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'clinicianName',
                                data: 'q4',
                                clinicianID: this.clinicianName.codigo,
                                clinicianRegion: this.clinicianRegion
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('region', '==', this.clinicianRegion).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clientName && !this.claimStatus && !this.clinicianRegion && this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('statusClinician', '==', this.clinicianStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clinicianID', '==', this.clinicianName.codigo).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clientName && this.clientName.value) {
                // QUERY CLIENT NAME
                if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'clientName',
                            data: 'q1',
                            clientUID: this.clientUID
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('client', '==', this.clientUID).where('clientName', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('client', '==', this.clientUID).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('client', '==', this.clientUID).where('regionBorough', '==', this.clinicianBorough).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                this.totalRowsQuery = this.itemsClaimsQuery.length
                                this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('client', '==', this.clientUID).where('region', '==', this.clinicianRegion).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                this.totalRowsQuery = this.itemsClaimsQuery.length
                                this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && this.clinicianStatus && !this.clientID && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('client', '==', this.clientUID).where('statusClinician', '==', this.clinicianStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID && this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('client', '==', this.clientUID).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clinicianStatus) {
                // QUERY STATUS CLINICIAN
                if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'clinicianStatus',
                            data: 'q1',
                            clinicianStatus: this.clinicianStatus
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'clinicianStatus',
                            data: 'q2',
                            clinicianStatus: this.clinicianStatus,
                            clinicianID: this.clinicianName.codigo
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clientID && this.clientName && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'clinicianStatus',
                            data: 'q3',
                            clinicianStatus: this.clinicianStatus,
                            clientUID: this.clientUID
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'clinicianStatus',
                                data: 'q4',
                                clinicianStatus: this.clinicianStatus,
                                borough: this.clinicianBorough
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('regionBorough', '==', this.clinicianBorough).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'clinicianStatus',
                                data: 'q5',
                                clinicianStatus: this.clinicianStatus,
                                region: this.clinicianRegion
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('region', '==', this.clinicianRegion).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && this.claimStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'clinicianStatus',
                            data: 'q6',
                            clinicianStatus: this.clinicianStatus,
                            claimStatus: this.claimStatus
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clientID && !this.clientName && this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'clinicianStatus',
                            data: 'q7',
                            clinicianStatus: this.clinicianStatus,
                            clinicianProgram: this.clinicianProgram
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('statusClinician', '==', this.clinicianStatus).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.claimStatus) {
                // QUERY STATUS CLINICIAN
                if(!this.clinicianName && !this.clinicianStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'claimStatus',
                            data: 'q1',
                            claimStatus: this.claimStatus
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            if(!this.clinicianName && !this.clinicianStatus && !this.clinicianRegion && !this.clientID && !this.clientName) {
                                this.totalRowsQuery = res.data
                            }
                            this.loadCounterQuery = false
                            
                            if(this.claimStatus === 'paid') {
                                // update total claims paid
                                db.collection('clientsClaims').doc('counter').update({
                                    totalClaimsPaid: res.data
                                })
                            }
                        })
                        .catch(e => console.log(e.message))
                    }

                    // get total cache claims paid
                    if(this.claimStatus === 'paid') {
                        db.collection('clientsClaims').doc('counter').get()
                        .then(counter => {
                            this.totalRowsQuery = counter.data().totalClaimsPaid ? counter.data().totalClaimsPaid : 11260
                            this.loadCounterQuery = false
                        })
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                        // }
                    // })
                } else if(this.clinicianName && !this.clinicianStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'claimStatus',
                            data: 'q2',
                            claimStatus: this.claimStatus,
                            clinicianID: this.clinicianName.codigo
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('approvalStatus', '==', this.claimStatus).where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.clinicianRegion && !this.clientID && this.clientName && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'claimStatus',
                            data: 'q3',
                            claimStatus: this.claimStatus,
                            clientUID: this.clientUID
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('approvalStatus', '==', this.claimStatus).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    if(this.clinicianBorough) {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'claimStatus',
                                data: 'q4',
                                claimStatus: this.claimStatus,
                                borough: this.clinicianBorough
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('approvalStatus', '==', this.claimStatus).where('regionBorough', '==', this.clinicianBorough).orderBy('status').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    } else {
                        if(!this.latestDocQuery) {
                            var body = {
                                type: 'claimStatus',
                                data: 'q5',
                                claimStatus: this.claimStatus,
                                region: this.clinicianRegion
                            }

                            var config = {
                            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                            'method': 'POST',
                            'timeout': 0,
                            'headers': {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            'data': JSON.stringify(body),
                            }

                            this.axios(config)
                            .then(res => {
                                // console.log(res.data)
                                this.totalRowsQuery = res.data
                                this.loadCounterQuery = false
                            })
                            .catch(e => console.log(e.message))
                        }

                        var ref = db.collection('clientsClaims').where('status', '==', 'active').where('approvalStatus', '==', this.claimStatus).where('region', '==', this.clinicianRegion).orderBy('status').startAfter(this.latestDocQuery || 0).limit(50)
                        var data = await ref.get()
                        // .then(docs => {
                            // console.log(docs.size)
                            // if(data.size >= 1) {
                                data.docs.forEach(dataClientClaim => {
                                    var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                    // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                    if(filterClaim.length === 0) {
                                        var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                        var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                        this.itemsClaimsQuery.push({
                                            id: dataClientClaim.id,
                                            status: dataClientClaim.data().status,
                                            clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                            clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                            clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                            clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                            approvalStatus: dataClientClaim.data().approvalStatus,
                                            lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                            claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                            claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                            dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                            amountBilledToCFE: amountBilledToCFE,
                                            region: dataClientClaim.data().region,
                                            regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                            clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                            clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                        })
                                    }
                                })
                                if(!data.empty) {
                                    this.latestDocQuery = data.docs[data.docs.length - 1]
                                }
                                this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                                this.apiLoaded = false
                                this.loadingInfiniteScroll = false
                                this.isBusy = false
                                // this.totalRowsQuery = this.itemsClaimsQuery.length
                                // this.loadCounterQuery = false
                            // }
                        // })
                    }
                } else if(!this.clinicianName && this.clinicianStatus && !this.clinicianRegion && !this.clientID && !this.clientName && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'claimStatus',
                            data: 'q6',
                            claimStatus: this.claimStatus,
                            statusClinician: this.clinicianStatus
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('approvalStatus', '==', this.claimStatus).where('statusClinician', '==', this.clinicianStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.clinicianRegion && !this.clientID && !this.clientName && this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'claimStatus',
                            data: 'q7',
                            claimStatus: this.claimStatus,
                            clinicianProgram: this.clinicianProgram
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('approvalStatus', '==', this.claimStatus).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clinicianRegion) {
                // QUERY STATUS CLINICIAN
                if(!this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && !this.clientName && !this.clinicianBorough && !this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'clinicianRegion',
                            data: 'q1',
                            region: this.clinicianRegion
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    // .collection("clientsClaims")
                    // .where("region", "==", "Central")
                    // var ref = db.collection('clientsClaims').where('region', '==', this.clinicianRegion).limit(50)
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('region', '==', this.clinicianRegion).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            // this.totalRowsQuery = this.itemsClaimsQuery.length
                            // this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && !this.clientName && !this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('region', '==', this.clinicianRegion).where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && this.clientName && !this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('region', '==', this.clinicianRegion).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && this.claimStatus && !this.clientID && !this.clientName && !this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('region', '==', this.clinicianRegion).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && !this.clientName && !this.clinicianBorough && this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'clinicianRegion',
                            data: 'q7',
                            region: this.clinicianRegion,
                            program: this.clinicianProgram
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }
                    
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('region', '==', this.clinicianRegion).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })

                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && !this.clientName && this.clinicianBorough && !this.clinicianProgram) {
                    // .collection("clientsClaims")
                    // .where("region", "==", "Central")
                    // var ref = db.collection('clientsClaims').where('region', '==', this.clinicianRegion).limit(50)
                    // console.log(this.clinicianBorough)
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('regionBorough', '==', this.clinicianBorough).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && !this.clientName && this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('regionBorough', '==', this.clinicianBorough).where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && !this.claimStatus && !this.clientID && this.clientName && this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('regionBorough', '==', this.clinicianBorough).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && this.claimStatus && !this.clientID && !this.clientName && this.clinicianBorough && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('regionBorough', '==', this.clinicianBorough).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.clinicianStatus && this.claimStatus && !this.clientID && !this.clientName && this.clinicianBorough && this.clinicianProgram) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'clinicianRegion',
                            data: 'q13',
                            regionBorough: this.clinicianBorough,
                            program: this.clinicianProgram
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('regionBorough', '==', this.clinicianBorough).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clientID) {
                // QUERY CLIENT ID
                if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientName && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clientID', '==', this.clientID).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientName && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clientID', '==', this.clientID).where('clinicianID', '==', this.clinicianName.codigo).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && this.clientName && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clientID', '==', this.clientID).where('client', '==', this.clientUID).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientName && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clientID', '==', this.clientID).where('approvalStatus', '==', this.claimStatus).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && this.clinicianRegion && !this.clinicianStatus && !this.clientName && !this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clientID', '==', this.clientID).where('region', '==', this.clinicianRegion).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else if(!this.clinicianName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientName && this.clinicianProgram) {
                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clientID', '==', this.clientID).where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()
                    // .then(docs => {
                        // console.log(docs.size)
                        // if(data.size >= 1) {
                            data.docs.forEach(dataClientClaim => {
                                var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                                // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                                if(filterClaim.length === 0) {
                                    var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                    var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                    this.itemsClaimsQuery.push({
                                        id: dataClientClaim.id,
                                        status: dataClientClaim.data().status,
                                        clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                        clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                        clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                        clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                        approvalStatus: dataClientClaim.data().approvalStatus,
                                        lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                        claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                        claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                        dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                        amountBilledToCFE: amountBilledToCFE,
                                        region: dataClientClaim.data().region,
                                        regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                        clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                        clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                                    })
                                }
                            })
                            if(!data.empty) {
                                this.latestDocQuery = data.docs[data.docs.length - 1]
                            }
                            this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                            this.apiLoaded = false
                            this.loadingInfiniteScroll = false
                            this.isBusy = false
                            this.totalRowsQuery = this.itemsClaimsQuery.length
                            this.loadCounterQuery = false
                        // }
                    // })
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            } else if(this.clinicianProgram) {
                // QUERY CLIENT ID
                if(!this.clinicianName && !this.clientName && !this.claimStatus && !this.clinicianRegion && !this.clinicianStatus && !this.clientID) {
                    if(!this.latestDocQuery) {
                        var body = {
                            type: 'clinicianProgram',
                            data: 'q1',
                            clinicianProgram: this.clinicianProgram
                        }

                        var config = {
                        'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
                        'method': 'POST',
                        'timeout': 0,
                        'headers': {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        'data': JSON.stringify(body),
                        }

                        this.axios(config)
                        .then(res => {
                            // console.log(res.data)
                            this.totalRowsQuery = res.data
                            this.loadCounterQuery = false
                        })
                        .catch(e => console.log(e.message))
                    }

                    var ref = db.collection('clientsClaims').where('status', '>=', 'active').where('clinicianProgram', '==', this.clinicianProgram).orderBy('status').orderBy('claimDate', 'desc').startAfter(this.latestDocQuery || 0).limit(50)
                    var data = await ref.get()

                    // ouputs docs
                    // .then(docsClients => {
                    data.docs.forEach(dataClientClaim => {
                        var filterClaim = this.itemsClaimsQuery.filter(ele => ele.id === dataClientClaim.id)
                        // if(dataClientClaim.data().clientName && dataClientClaim.data().clinicianName && filterClaim.length === 0) {
                        if(filterClaim.length === 0) {
                            var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                            var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                            this.itemsClaimsQuery.push({
                                id: dataClientClaim.id,
                                status: dataClientClaim.data().status,
                                clientID: dataClientClaim.data().clientID && dataClientClaim.data().clinicianProgram !== 'PGRC - OASAS Program' ? '#' + dataClientClaim.data().clientID : (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : ''),
                                clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                                clinicianID: dataClientClaim.data().clinicianID ? '#' + dataClientClaim.data().clinicianID : '',
                                clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                                approvalStatus: dataClientClaim.data().approvalStatus,
                                lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                                claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                                claimDateFormat: '01/'+moment().month(dataClientClaim.data().claimDateMonth).format("MM")+ '/' + dataClientClaim.data().claimDateYear,
                                claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                                dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                                amountBilledToCFE: amountBilledToCFE,
                                region: dataClientClaim.data().region,
                                regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                                clinicianStatus: dataClientClaim.data().statusClinician ? dataClientClaim.data().statusClinician : '',
                                clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                            })
                        }
                    })
                    // console.log(this.itemsClaimsQuery.length)
                    if(!data.empty) {
                        this.latestDocQuery = data.docs[data.docs.length - 1]
                    }
                    this.itemsClaimsQuery.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
                    this.apiLoaded = false
                    this.loadingInfiniteScroll = false
                    this.isBusy = false
                } else {
                    this.activeFilters = true
                    this.activeFiltersDate = false
                }
            }

            this.json_data = []
        },
        checkActiveFilters() {
            if(this.clinicianName || this.clientName || this.claimStatus || this.clinicianRegion || this.clinicianBorough || this.clinicianStatus || this.startDate || this.endDate || this.clientID || this.clinicianProgram) {
                this.clearSelected()
                this.activeFilters = true
                this.activeFiltersDate = false
                
                // !this.isCompleteData
                // if(this.clientName || this.clinicianName) {
                if(this.clientName || this.clinicianName || (this.startDate && this.endDate) || this.clinicianRegion || this.clinicianBorough || this.claimStatus || this.clinicianStatus || this.clinicianProgram) {
                    this.queryClaims()
                } 
                else if(!this.clientID) {
                // else if(!this.clinicianRegion && !this.clientID && !this.claimStatus && !this.clinicianStatus && !this.clinicianBorough && !this.startDate && !this.endDate) {
                    this.queryClaims()
                }
            } else {
                this.clearSelected()
                this.activeFilters = false
                this.activeFiltersDate = false
            }

            if(this.activeFilters && !this.activeFiltersDate) {
                this.perPage = 3000
            }
        },
        BtnActivateClaim(val) {
            db.collection('clientsClaims').doc(val).update({
                status: 'active'
            })
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Claim activated`,
                        icon: 'CoffeeIcon',
                        variant: 'success'
                    },
                })
            })

            // snapshot clinician
            db.collection('clientsClaims').doc(val).get()
            .then(dataClientClaim => {
                if(dataClientClaim.data().clinician) {
                    db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                        updated: Date.now()
                    })
                }
            })
            // console.log(val)
        },
        BtnDeactivateClaim(val) {
            db.collection('clientsClaims').doc(val).update({
                status: 'inactive'
            })
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Claim diabled`,
                        icon: 'CoffeeIcon',
                        variant: 'success'
                    },
                })
            })

            // snapshot clinician
            db.collection('clientsClaims').doc(val).get()
            .then(dataClientClaim => {
                if(dataClientClaim.data().clinician) {
                    db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                        updated: Date.now()
                    })
                }
            })
            // console.log(val)
        },
        onRowSelected(items) {
            this.idClientClaim = items.id
            this.statusEdit = true
            this.clearSelected()
            // console.log(this.idClientActivity)
        },
        onRowSelectedApproved(items) {
            // console.log(items)
            this.selected = items
        },
        functionPageSelected(payload) {
            setTimeout(() => {
                // console.log(payload.item.id, payload.item.rowSelected)
                if(payload.item.rowSelected) {
                    payload.item.rowSelected = true
                    // payload.item.selectedInPro = true
                    payload.rowSelected = true
                    this.$refs.selectableTable.selectRow(payload.index)
                } else {
                    payload.item.rowSelected = false
                    // payload.item.selectedInPro = true
                    payload.rowSelected = false
                    this.$refs.selectableTable.unselectRow(payload.index)
                }
            }, 500)
        },
        observerCheckbox(item) {
            // console.log(item)
            if(item.rowSelected) {
                this.$refs.selectableTable.unselectRow(item.index)
            } else {
                this.$refs.selectableTable.selectRow(item.index)
            }
        },
        selectAllRows() {
            this.selectedReset = true
            // this.$refs.selectableTable.selectAllRows()

            if(this.activeFilters && !this.activeFiltersDate) {
                this.arrayFiltradoClaims.forEach(ele => {
                    if(!ele.rowSelected && (ele.approvalStatus === 'pending dm approval' || ele.approvalStatus === 'pending pm approval' || ele.approvalStatus === 'approved')) {
                        ele.rowSelected = true
                    } else {
                        ele.rowSelected = false
                    }
                })
            } else if(this.activeFilters && this.activeFiltersDate) {
                this.itemsClaimsQuery.forEach(ele => {
                    if(!ele.rowSelected && (ele.approvalStatus === 'pending dm approval' || ele.approvalStatus === 'pending pm approval' || ele.approvalStatus === 'approved')) {
                        ele.rowSelected = true
                    } else {
                        ele.rowSelected = false
                    }
                })
            } else if(!this.activeFilters) {
                this.itemsClaims.forEach(ele => {
                    if(!ele.rowSelected && (ele.approvalStatus === 'pending dm approval' || ele.approvalStatus === 'pending pm approval' || ele.approvalStatus === 'approved')) {
                        ele.rowSelected = true
                    } else {
                        ele.rowSelected = false
                    }
                })
            }
        },
        clearSelected() {
            this.selectedReset = false
            this.selected = []
            this.$refs.selectableTable.clearSelected()

            if(this.activeFilters && !this.activeFiltersDate) {
                this.arrayFiltradoClaims.forEach(ele => {
                    // if(ele.rowSelected) {
                        ele.rowSelected = false
                    // }
                })
            } else if(this.activeFilters && this.activeFiltersDate) {
                this.itemsClaimsQuery.forEach(ele => {
                    // if(ele.rowSelected) {
                        ele.rowSelected = false
                    // }
                })
            } else if(!this.activeFilters) {
                this.itemsClaims.forEach(ele => {
                    // if(ele.rowSelected) {
                        ele.rowSelected = false
                    // }
                })
            }
        },
        approveClaimsSelected() {
            if(this.selected.length >= 1) {
                this.isBusy = true
                this.apiLoaded = true

                if(this.selectedReset) {
                    this.arrayFiltradoClaims.forEach(ele => {
                        if(ele.id) {
                            if(ele.approvalStatus === 'pending dm approval') {
                                db.collection('clientsClaims').doc(ele.id).update({
                                    approvalStatus: 'approved',
                                    lastApprovalStatus: 'pending dm approval'
                                })
                                .then(() => {
                                    // snapshot clinician
                                    db.collection('clientsClaims').doc(ele.id).get()
                                    .then(dataClientClaim => {
                                        if(dataClientClaim.data().clinician) {
                                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                                updated: Date.now()
                                            })
                                        }
                                    })
                                })
                                .catch(e => console.log(e))
                            } else if(ele.approvalStatus === 'pending pm approval') {
                                db.collection('clientsClaims').doc(ele.id).update({
                                    approvalStatus: 'pending dm approval',
                                    lastApprovalStatus: 'pending pm approval'
                                })
                                .then(() => {
                                    // snapshot clinician
                                    db.collection('clientsClaims').doc(ele.id).get()
                                    .then(dataClientClaim => {
                                        if(dataClientClaim.data().clinician) {
                                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                                updated: Date.now()
                                            })
                                        }
                                    })
                                })
                                .catch(e => console.log(e))
                            } else if(ele.approvalStatus === 'approved') {
                                db.collection('clientsClaims').doc(ele.id).update({
                                    approvalStatus: 'paid',
                                    lastApprovalStatus: 'approved',
                                    datePaid: Date.now(),
                                    paidBy: this.userUID
                                })
                                .then(() => {
                                    // snapshot clinician
                                    db.collection('clientsClaims').doc(ele.id).get()
                                    .then(dataClientClaim => {
                                        if(dataClientClaim.data().clinician) {
                                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                                updated: Date.now()
                                            })
                                        }
                                    })
                                })
                                .catch(e => console.log(e))
                            }
                        }
                    })

                    this.clearSelected()
                    this.reloadData()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Claims processed`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                        },
                    })
                } else {
                    this.selected.forEach(ele => {
                        if(ele.id) {
                            if(ele.approvalStatus === 'pending dm approval') {
                                db.collection('clientsClaims').doc(ele.id).update({
                                    approvalStatus: 'approved',
                                    lastApprovalStatus: 'pending dm approval'
                                })
                                .then(() => {
                                    // snapshot clinician
                                    db.collection('clientsClaims').doc(ele.id).get()
                                    .then(dataClientClaim => {
                                        if(dataClientClaim.data().clinician) {
                                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                                updated: Date.now()
                                            })
                                        }
                                    })
                                })
                                .catch(e => console.log(e))
                            } else if(ele.approvalStatus === 'pending pm approval') {
                                db.collection('clientsClaims').doc(ele.id).update({
                                    approvalStatus: 'pending dm approval',
                                    lastApprovalStatus: 'pending pm approval'
                                })
                                .then(() => {
                                    // snapshot clinician
                                    db.collection('clientsClaims').doc(ele.id).get()
                                    .then(dataClientClaim => {
                                        if(dataClientClaim.data().clinician) {
                                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                                updated: Date.now()
                                            })
                                        }
                                    })
                                })
                                .catch(e => console.log(e))
                            } else if(ele.approvalStatus === 'approved') {
                                db.collection('clientsClaims').doc(ele.id).update({
                                    approvalStatus: 'paid',
                                    lastApprovalStatus: 'approved',
                                    datePaid: Date.now(),
                                    paidBy: this.userUID
                                })
                                .then(() => {
                                    // snapshot clinician
                                    db.collection('clientsClaims').doc(ele.id).get()
                                    .then(dataClientClaim => {
                                        if(dataClientClaim.data().clinician) {
                                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                                updated: Date.now()
                                            })
                                        }
                                    })
                                })
                                .catch(e => console.log(e))
                            }
                        }
                    })

                    this.clearSelected()
                    this.reloadData()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Claims processed`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                        },
                    })
                }
            } else {
                this.isBusy = false
                this.apiLoaded = false
                
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `No claims to processed`,
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                })
            }
        },
        markAsPaidClaim(val) {
            if(val) {
                this.isBusy = true
                this.apiLoaded = true
                
                db.collection('clientsClaims').doc(val).update({
                    approvalStatus: 'paid',
                    lastApprovalStatus: 'approved',
                    datePaid: Date.now(),
                    paidBy: this.userUID
                })
                .then(() => {
                    // snapshot clinician
                    db.collection('clientsClaims').doc(val).get()
                    .then(dataClientClaim => {
                        if(dataClientClaim.data().clinician) {
                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                updated: Date.now()
                            })
                        }
                    })

                    this.clearSelected()
                    this.reloadData()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Claims processed`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                        },
                    })
                })
                .catch(e => {
                    console.log(e)

                    this.isBusy = false
                    this.apiLoaded = false
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `No claims to processed`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    })
                })
            }
        },
        returnBackApprovalStatus(val) {
            if(val.id) {
                db.collection('clientsClaims').doc(val.id).update({
                    approvalStatus: val.last,
                    lastApprovalStatus: val.type,
                })
                .then(() => {
                    this.reloadData()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Claim updated to ${val.last}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                        },
                    })
                    // snapshot clinician
                    db.collection('clientsClaims').doc(val.id).get()
                    .then(dataClientClaim => {
                        if(dataClientClaim.data().clinician) {
                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                updated: Date.now()
                            })
                        }
                    })
                })
                .catch(e => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Error`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    })
                    console.log(e)
                })
            }
        },
        markAsApproveClaim(val) {
            if(val.id) {
                db.collection('clientsClaims').doc(val.id).update({
                    approvalStatus: val.type,
                    lastApprovalStatus: val.act,
                })
                .then(() => {
                    this.reloadData()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Claim updated to ${val.type}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                        },
                    })
                    // snapshot clinician
                    db.collection('clientsClaims').doc(val.id).get()
                    .then(dataClientClaim => {
                        if(dataClientClaim.data().clinician) {
                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                updated: Date.now()
                            })
                        }
                    })
                })
                .catch(e => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Error`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    })
                    console.log(e)
                })
            }
        },
        markAsDenyClaim(val) {
            if(val.id) {
                db.collection('clientsClaims').doc(val.id).update({
                    approvalStatus: val.type,
                    lastApprovalStatus: val.act,
                })
                .then(() => {
                    this.reloadData()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Claim updated to ${val.type}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                        },
                    })
                    // snapshot clinician
                    db.collection('clientsClaims').doc(val.id).get()
                    .then(dataClientClaim => {
                        if(dataClientClaim.data().clinician) {
                            db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                updated: Date.now()
                            })
                        }
                    })
                })
                .catch(e => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Error`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    })
                    console.log(e)
                })
            }
        },
        processResumeClinician(val) {
            // console.log(val)
            if(val) {
                this.showOverlay = true
                this.isBusy = true
                this.apiLoaded = true
                this.isBusyResumeApproved = true
                this.latestDocQuery = null
                this.loadCounterQuery = true

                db.collection('usuarios').doc(val).get()
                .then(clinician => {
                    db.collection('clientsClaims').where('status', '==', 'active').where('clinician', '==', val).get()
                    .then(claimsClinician => {
                        var arrayApprove = []
                        var countClaim = 1
                        var isZeroClaim = false
                        var amountBilledToCFEQuick = 0
                        claimsClinician.forEach(doc => {
                            if(doc.data().approvalStatus === 'approved') {
                                // this.showOverlay = true
                                // this.isBusy = true
                                // this.apiLoaded = true
                                // this.isBusyResumeApproved = true
                                var validateFixed = doc.data().amountBilledToCFE.includes('.') ? parseFloat(doc.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(doc.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                                var amountBilledToCFEParcial = doc.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))
                                amountBilledToCFEQuick += doc.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                                if(amountBilledToCFEQuick >= 1) {
                                    isZeroClaim = true
                                }

                                var objAppr = {
                                    "DetailType": "AccountBasedExpenseLineDetail", 
                                    "Amount": amountBilledToCFEParcial,
                                    "Id": countClaim.toString(), 
                                    "AccountBasedExpenseLineDetail": {
                                        "AccountRef": {
                                            "value": "94"
                                        },
                                        "ClassRef": {
                                            "value": "5000000000000226817"                   
                                        }
                                    }
                                }

                                arrayApprove.push(objAppr)
                                countClaim += 1
                            }
                        })

                        if(isZeroClaim) {
                            if(clinician.data().vendorId) {
                                console.log(1,clinician.data().vendorId,amountBilledToCFEQuick)
                                var json_body = {
                                    "Line": arrayApprove,
                                    "SalesTermRef": {
                                        "value": "3"
                                    },
                                    "VendorRef": {
                                        "value": clinician.data().vendorId
                                    },
                                    "PrivateNote": "Clinician Services"
                                }

                                var settingsBill = {
                                    "url": "https://nycpg-342916.uc.r.appspot.com/api/bills",
                                    "method": "POST",
                                    "timeout": 0,
                                    "headers": {
                                        "Content-Type": "application/json"
                                    },
                                    "data": JSON.stringify(json_body),
                                };

                                this.axios(settingsBill)
                                .then((responseBill) => {
                                    console.log(responseBill.data.Bill.Id)

                                    // var json_body = {
                                    //     "PrivateNote": "Clinician Services", 
                                    //     "VendorRef": {
                                    //         "value": clinician.data().vendorId
                                    //     }, 
                                    //     "TotalAmt": amountBilledToCFEQuick,
                                    //     "PayType": "Check", 
                                    //     "Line": [
                                    //         {
                                    //             "Amount": amountBilledToCFEQuick, 
                                    //             "LinkedTxn": [
                                    //                 {
                                    //                 "TxnId": responseBill.data.Bill.Id, 
                                    //                 "TxnType": "Bill"
                                    //                 }
                                    //             ]
                                    //         }
                                    //     ], 
                                    //     "CheckPayment": {
                                    //         "BankAccountRef": {
                                    //             "name": "Checking", 
                                    //             "value": "35"
                                    //         }
                                    //     }
                                    // }

                                    // var settingsPaid = {
                                    //     "url": "https://nycpg-342916.uc.r.appspot.com/api/bills/paid",
                                    //     "method": "POST",
                                    //     "timeout": 0,
                                    //     "headers": {
                                    //         "Content-Type": "application/json"
                                    //     },
                                    //     "data": JSON.stringify(json_body),
                                    // };
                                    // this.axios(settingsPaid)
                                    // .then((responsePaid) => {
                                    //     console.log(clinician.data().vendorId,responseBill.data.Bill.Id,responsePaid)
                                        claimsClinician.forEach(docCl => {
                                            db.collection('clientsClaims').doc(docCl.id).update({
                                                approvalStatus: 'paid',
                                                lastApprovalStatus: docCl.data().approvalStatus,
                                                datePaid: Date.now(),
                                                paidBy: this.userUID
                                            })

                                            // snapshot clinician
                                            db.collection('clientsClaims').doc(docCl.id).get()
                                            .then(dataClientClaim => {
                                                if(dataClientClaim.data().clinician) {
                                                    db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                                        updated: Date.now()
                                                    })
                                                }
                                            })
                                        })

                                        this.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: `Claims processed successfully`,
                                                icon: 'CoffeeIcon',
                                                variant: 'success'
                                            },
                                        })

                                        this.reloadData()
                                        this.showOverlay = false
                                    // })
                                    // .catch(e => {
                                    //     console.log(e)
                                    //     this.isBusyResumeApproved = false
                                    //     this.$toast({
                                    //         component: ToastificationContent,
                                    //         position: 'top-right',
                                    //         props: {
                                    //             title: `Error processing the claims`,
                                    //             icon: 'CoffeeIcon',
                                    //             variant: 'danger'
                                    //         },
                                    //     })
                                    // })
                                })
                                .catch(e => {
                                    console.log(e)
                                    this.showOverlay = false
                                    this.isBusyResumeApproved = false
                                    this.isBusy = false
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Error processing the claim`,
                                            icon: 'CoffeeIcon',
                                            variant: 'danger'
                                        },
                                    })
                                })
                            } else {
                                console.log(2, amountBilledToCFEQuick)
                                var City = ''
                                var Country = "U.S.A"
                                var Line3 = ''
                                var Line2 = ''
                                var Line1 = ''
                                var PostalCode = ''
                                var CountrySubDivisionCode = ''
                                if(clinician.data().referralDetail && clinician.data().referralDetail.length >= 1) {
                                    City = clinician.data().referralDetail[0].city
                                    Country = "U.S.A"
                                    Line3 = clinician.data().referralDetail[0].address
                                    Line2 = ''
                                    Line1 = clinician.data().agency,
                                    PostalCode = clinician.data().referralDetail[0].zip
                                    CountrySubDivisionCode = clinician.data().referralDetail[0].state
                                }

                                var json_body = {
                                    "PrimaryEmailAddr": {
                                        "Address": clinician.data().email
                                    }, 
                                    "WebAddr": {
                                        "URI": ''
                                    }, 
                                    "PrimaryPhone": {
                                        "FreeFormNumber": clinician.data().contact
                                    }, 
                                    "DisplayName": clinician.data().firstName + ' ' + clinician.data().lastName,
                                    "Suffix": "Sr.", 
                                    "Title": "Ms.", 
                                    "Mobile": {
                                        "FreeFormNumber": clinician.data().contact
                                    }, 
                                    "FamilyName": clinician.data().firstName, 
                                    "TaxIdentifier": "", 
                                    "AcctNum": "", 
                                    "CompanyName": clinician.data().agency, 
                                    "BillAddr": {
                                        "City": City, 
                                        "Country": Country, 
                                        "Line3": Line3, 
                                        "Line2": Line2, 
                                        "Line1": Line1, 
                                        "PostalCode": PostalCode, 
                                        "CountrySubDivisionCode": CountrySubDivisionCode
                                    }, 
                                    "GivenName": clinician.data().firstName, 
                                    "PrintOnCheckName": clinician.data().firstName + ' ' + clinician.data().lastName
                                }

                                var settingsVendor = {
                                    "url": "https://nycpg-342916.uc.r.appspot.com/api/vendors",
                                    "method": "POST",
                                    "timeout": 0,
                                    "headers": {
                                        "Content-Type": "application/json"
                                    },
                                    "data": JSON.stringify(json_body)
                                };

                                this.axios(settingsVendor)
                                .then((responseVendor) => {
                                    console.log(responseVendor.data.Vendor.Id)
                                    db.collection('usuarios').doc(val).update({
                                        vendorId: responseVendor.data.Vendor.Id
                                    })

                                    var json_body = {
                                        "Line": arrayApprove,
                                        "SalesTermRef": {
                                            "value": "3"
                                        },
                                        "VendorRef": {
                                            "value": responseVendor.data.Vendor.Id
                                        },
                                        "PrivateNote": "Clinician Services"
                                    }

                                    var settingsBill = {
                                        "url": "https://nycpg-342916.uc.r.appspot.com/api/bills",
                                        "method": "POST",
                                        "timeout": 0,
                                        "headers": {
                                            "Content-Type": "application/json"
                                        },
                                        "data": JSON.stringify(json_body),
                                    };

                                    this.axios(settingsBill)
                                    .then((responseBill) => {
                                        console.log(responseBill.data.Bill.Id)

                                        // var json_body = {
                                        //     "PrivateNote": "Clinician Services", 
                                        //     "VendorRef": {
                                        //         "value": responseVendor.data.Vendor.Id
                                        //     }, 
                                        //     "TotalAmt": amountBilledToCFEQuick,
                                        //     "PayType": "Check", 
                                        //     "Line": [
                                        //         {
                                        //             "Amount": amountBilledToCFEQuick, 
                                        //             "LinkedTxn": [
                                        //                 {
                                        //                 "TxnId": responseBill.data.Bill.Id, 
                                        //                 "TxnType": "Bill"
                                        //                 }
                                        //             ]
                                        //         }
                                        //     ], 
                                        //     "CheckPayment": {
                                        //         "BankAccountRef": {
                                        //             "name": "Checking", 
                                        //             "value": "35"
                                        //         }
                                        //     }
                                        // }

                                        // var settingsPaid = {
                                        //     "url": "https://nycpg-342916.uc.r.appspot.com/api/bills/paid",
                                        //     "method": "POST",
                                        //     "timeout": 0,
                                        //     "headers": {
                                        //         "Content-Type": "application/json"
                                        //     },
                                        //     "data": JSON.stringify(json_body),
                                        // };
                                        // this.axios(settingsPaid)
                                        // .then((responsePaid) => {
                                        //     console.log(responseVendor.data.Vendor.Id,responseBill.data.Bill.Id,responsePaid)
                                            claimsClinician.forEach(docCl => {
                                                // console.log(docCl.id)
                                                db.collection('clientsClaims').doc(docCl.id).update({
                                                    approvalStatus: 'paid',
                                                    lastApprovalStatus: docCl.data().approvalStatus,
                                                    datePaid: Date.now(),
                                                    paidBy: this.userUID
                                                })

                                                // snapshot clinician
                                                db.collection('clientsClaims').doc(docCl.id).get()
                                                .then(dataClientClaim => {
                                                    if(dataClientClaim.data().clinician) {
                                                        db.collection('usuarios').doc(dataClientClaim.data().clinician).update({
                                                            updated: Date.now()
                                                        })
                                                    }
                                                })
                                            })

                                            this.$toast({
                                                component: ToastificationContent,
                                                position: 'top-right',
                                                props: {
                                                    title: `Claim processed successfully`,
                                                    icon: 'CoffeeIcon',
                                                    variant: 'success'
                                                },
                                            })
                                            this.reloadData()
                                            this.showOverlay = false
                                            // this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/approved/claims')
                                            // .then(res => {
                                            //     this.isBusyResumeApproved = false
                                            //     this.cliniciansResume = res.data
                                            // })
                                        // })
                                        // .catch(e => {
                                        //     console.log(e)
                                        //     this.isBusyResumeApproved = false
                                        //     this.$toast({
                                        //         component: ToastificationContent,
                                        //         position: 'top-right',
                                        //         props: {
                                        //             title: `Error processing the claim`,
                                        //             icon: 'CoffeeIcon',
                                        //             variant: 'danger'
                                        //         },
                                        //     })
                                        // })
                                    })
                                    .catch(e => {
                                        console.log(e)
                                        this.showOverlay = false
                                        this.isBusyResumeApproved = false
                                        this.isBusy = false
                                        this.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: `Error processing the claim`,
                                                icon: 'CoffeeIcon',
                                                variant: 'danger'
                                            },
                                        })
                                    })
                                })
                                .catch(error => {
                                    console.log(error)
                                    this.showOverlay = false
                                    this.isBusyResumeApproved = false
                                    this.isBusy = false
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Error processing the claim`,
                                            icon: 'CoffeeIcon',
                                            variant: 'danger'
                                        },
                                    })
                                })
                            }
                        } else {
                            this.showOverlay = false
                            this.isBusyResumeApproved = false
                            this.isBusy = false
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Claims cannot be processed`,
                                    icon: 'CoffeeIcon',
                                    variant: 'success',
                                    text: 'Because exist claims with Amount Billed to PGRC is $0.00'
                                },
                            })
                        }
                        // this.showOverlay = false
                        // this.isBusy = false
                        // this.apiLoaded = false
                        // this.isBusyResumeApproved = false
                                // }
                            // })
                        // })
                    })
                })
            }
        },
        reloadData() {
            this.isBusyResumeApproved = true
            this.isCompleteData = false
            this.apiLoaded = true
            this.loadCounterQuery = true
            this.isBusy = true
            this.itemsClaims = []
            this.latestDoc = null
            this.latestDocQuery = null
            this.getClaimsLocal()
            this.itemsClaimsQuery = []
            this.queryClaims()
            this.selectedReset = false
            this.clearSelected()

            setTimeout(() => {
                // this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/approved/claims')
                // .then(res => {
                //     this.isBusyResumeApproved = false
                //     this.cliniciansResume = res.data
                // })

                this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/active/claims')
                .then(res => {
                    this.isCompleteData = true
                    this.apiLoaded = true
                    this.isBusy = false

                    this.setSnapshotClaims(res.data)
                })
            }, 3000)
        },
        async getClaimsLocal() {
            // db.collection
            // if(this.itemsClaims !== 0) {
            // const ref = db.collection('clientsClaims').where('status', '>=', 'active').orderBy('status').startAfter(this.latestDoc || 0).limit(25)
            const ref = db.collection("clientsClaims").where('status', '>=', 'active').orderBy('status').orderBy("claimDate", "desc").startAfter(this.latestDoc || 0).limit(50)
            const data = await ref.get()

            // ouputs docs
            // .then(docsClients => {
            data.docs.forEach(dataClientClaim => {
                // console.log(dataClientClaim.data().status)
                var validateFixed = dataClientClaim.data().amountBilledToCFE.includes('.') ? parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')) : parseFloat(dataClientClaim.data().amountBilledToCFE.replace(/[$]/g, '')).toFixed(2)
                var amountBilledToCFE = dataClientClaim.data().amountBilledToCFE ? parseFloat(validateFixed) : parseFloat((0).toFixed(2))

                var obj = {
                    id: dataClientClaim.id,
                    status: dataClientClaim.data().status,
                    clientID: '#' + (dataClientClaim.data().clinicianProgram === 'PGRC - OASAS Program' ? dataClientClaim.data().idOASASProgram : dataClientClaim.data().clientID),
                    clientName: dataClientClaim.data().clientName ? dataClientClaim.data().clientName : '',
                    clinicianID: '#' + dataClientClaim.data().clinicianID,
                    clinicianName: dataClientClaim.data().clinicianName ? dataClientClaim.data().clinicianName : '',
                    approvalStatus: dataClientClaim.data().approvalStatus,
                    lastApprovalStatus: dataClientClaim.data().lastApprovalStatus ? dataClientClaim.data().lastApprovalStatus : dataClientClaim.data().approvalStatus === 'paid' ? 'approved' : '',
                    claimDate: dataClientClaim.data().claimDateMonth + ' ' + dataClientClaim.data().claimDateYear,
                    claimDateFormat2: dataClientClaim.data().claimDateYear + '/' + moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01 00:00:00',
                    dateFormatOrder: new Date(dataClientClaim.data().claimDateYear + '-' +  moment().month(dataClientClaim.data().claimDateMonth).format("MM") + '/01'),
                    amountBilledToCFE: amountBilledToCFE,
                    region: dataClientClaim.data().region,
                    regionBorough: dataClientClaim.data().regionBorough ? dataClientClaim.data().regionBorough : '',
                    clinicianStatus: dataClientClaim.data().statusClinician,
                    clinicianProgram: dataClientClaim.data().clinicianProgram ? dataClientClaim.data().clinicianProgram : ''
                }
                this.itemsClaims.push(obj)
            })
            // console.log(this.itemsClaims.length)
            this.itemsClaims.sort((a, b) => b.dateFormatOrder - a.dateFormatOrder)
            this.latestDoc = data.docs[data.docs.length - 1]
            this.loadingInfiniteScroll = false
            this.isBusy = false
            this.selectedReset ? this.selectAllRows() : null
            if(data.empty) {
                window.removeEventListener('scroll', this.handleScroll)
            }
        },
        handleScroll(event) {
        },
        loadMoreClaims() {
            if(!this.activeFilters) {
                this.getClaimsLocal()
            }
            this.loadingInfiniteScroll = true
            this.perPage += 10
        },
        loadMoreClaimsFilters() {
            if(this.activeFilters) {
                this.queryClaims()
            }
            this.loadingInfiniteScroll = true
            this.perPage += 10
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    mounted() {
        this.activeFiltersDate = false
        this.itemsClaims = []
        this.cliniciansResume = []
        // setInterval(function() {
        this.getClaimsLocal()
        // }, 1000)

        this.optionsClientName = []
        this.optionsClinicianName = []
        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/all-clinicians')
        .then(res => {
            this.optionsClinicianName = [{id: 0, uid: 0, value: 'All Clinicians', full: 'All Clinicians'}]
            res.data.forEach(ele => {
                this.optionsClinicianName.push(ele)
            })
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clients')
        .then(res => { 
            this.optionsClientName = [{id: 0, uid: 0, value: 'All Clients'}]
            res.data.forEach(ele => {
                if(this.clientName) {
                    var filterName = ele.value ? ele.value.toLowerCase() : ''
                    if(filterName && filterName === this.clientName) {
                        this.clientName = ele
                    }
                }
                this.optionsClientName.push(ele)
            })
        })

        // this.optionsClientName = [{value: null, text: 'Client Name'}]
        // this.optionsClinicianName = [{value: null, text: 'Clinician Name'}]
        // this.getUsers()

        window.addEventListener('scroll', this.handleScroll)
    },
    created() {
        this.selected = []
        this.readyToExport = true
        this.loadCounterAll = true
        this.clinicianProgram = null
        this.selectedReset = false

        var getRegionState = null
        var getBoroughState = null
        if(this.searchClaimClinicianRegionState) {
            if(this.searchClaimClinicianRegionState === 'nyc') {
                getRegionState = 'NYC'
            } else if(this.searchClaimClinicianRegionState === 'central') {
                getRegionState = 'Central'
            } else if(this.searchClaimClinicianRegionState === 'finger lakes') {
                getRegionState = 'Finger Lakes'
            } else if(this.searchClaimClinicianRegionState === 'long island') {
                getRegionState = 'Long Island'
            } else if(this.searchClaimClinicianRegionState === 'mid-hudson') {
                getRegionState = 'Mid-Hudson'
            } else if(this.searchClaimClinicianRegionState === 'northeast') {
                getRegionState = 'Northeast'
            } else if(this.searchClaimClinicianRegionState === 'western') {
                getRegionState = 'Western'
            } else if(this.searchClaimClinicianRegionState === 'bronx') {
                getBoroughState = 'Bronx'
                getRegionState = 'NYC'
            } else if(this.searchClaimClinicianRegionState === 'brooklyn') {
                getBoroughState = 'brooklyn'
                getRegionState = 'NYC'
            } else if(this.searchClaimClinicianRegionState === 'manhattan') {
                getBoroughState = 'manhattan'
                getRegionState = 'NYC'
            } else if(this.searchClaimClinicianRegionState === 'queens') {
                getBoroughState = 'Queens'
                getRegionState = 'NYC'
            } else if(this.searchClaimClinicianRegionState === 'staten island') {
                getBoroughState = 'Staten Island'
                getRegionState = 'NYC'
            }
        }

        this.clinicianName = this.searchClaimClinicianNameState ? this.searchClaimClinicianNameState : null
        this.clientName = this.searchClaimNameState ? this.searchClaimNameState : null
        this.claimStatus = this.searchClaimStatusState ? this.searchClaimStatusState : null
        // this.clinicianRegion = this.searchClaimClinicianRegionState ? this.searchClaimClinicianRegionState : null
        this.clinicianRegion = getRegionState
        this.clinicianBorough = getBoroughState
        this.clinicianStatus = this.searchClaimClinicianStatusState ? this.searchClaimClinicianStatusState.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : null
        this.startDate = this.searchClaimStartDateState ? this.searchClaimStartDateState : null
        this.endDate = this.searchClaimEndDateState ? this.searchClaimEndDateState : null
        this.clientID = this.searchClaimIDState ? this.searchClaimIDState : null
        this.clinicianProgram = this.searchClaimClinicianProgramState ?  this.searchClaimClinicianProgramState : null

        this.userUID = ''
        const user = auth.currentUser
        if(user) {
            this.userUID = user.uid
            db.collection('usuarios').doc(user.uid).get()
            .then(userDoc => {
                if(userDoc.data().role === 'admin') {
                    this.adminOrManageUsers = true
                    this.isAdmin = true
                } else if(userDoc.data().role === 'staff') {
                    if(userDoc.data().permissions.includes('manage users')) {
                        this.adminOrManageUsers = true
                    } else if(userDoc.data().permissions.includes('data specialist')) {
                        this.dataSpecialist = true
                    } else if(userDoc.data().permissions.includes('clinician viewer')) {
                        this.clinicianOrClinicianViewer = true
                    } else {
                        this.clinicianOrClinicianViewer = true
                    }
                } else if(userDoc.data().role === 'clinician') {
                    this.isClinician = true
                }
            })
        }

        // get active claims
        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/active/claims')
        .then(res => {
            this.isCompleteData = true
            this.apiLoaded = true
            this.isBusy = false

            this.setSnapshotClaims(res.data)
        })

        // this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/approved/claims')
        // .then(res => {
        //     // this.apiLoaded = true
        //     this.isBusyResumeApproved = false
        //     // console.log(res.data)
        //     this.cliniciansResume = res.data
        // })

        var body = {
            type: 'all'
        }

        var config = {
            'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/allCounters/api/counters/all-claims',
            'method': 'POST',
            'timeout': 0,
            'headers': {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            'data': JSON.stringify(body),
        }

        this.axios(config)
        .then(res => {
            // console.log(res.data)
            this.totalRows = res.data
            this.loadCounterAll = false

            db.collection('clientsClaims').doc('counter').update({
                totalClaims: res.data
            })
        })
        .catch(e => console.log(e.message))

        db.collection('clientsClaims').doc('counter').get()
        .then(counter => {
            this.totalRows = counter.data().totalClaims
            this.loadCounterAll = false
            // this.isBusy = false
        })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            this.optionsClinicianRegion = [{value: null, text: 'Any Region'}]
            docTaxonomia.data().region.forEach(item => {
                this.optionsClinicianRegion.push({value: item, text: item})
            })

            var arrayProgram = [{value: null, text: 'Any Program'}]
            docTaxonomia.data().program.forEach(item => {
                arrayProgram.push({value: item, text: item})
            })
            this.optionsProgram = arrayProgram
        })

        this.searchClaimClinicianName('')
        this.searchClaimClientName('')
        this.searchClaimClaimStatus('')
        this.searchClaimClinicianRegion('')
        this.searchClaimClinicianStatus('')
        this.searchClaimStartDate('')
        this.searchClaimEndDate('')
        this.searchClaimsClientID('')
        this.searchClaimClinicianProgram('')
    }
}
</script>

<style>
.thClaimsSelectable {
    padding: 5px !important;
    width: 30px !important;
}
.tdClaimsSelectable {
    padding: 5px !important;
    width: 30px !important;
}
.thClaimsActions {
    padding: 0px !important;
}
.tdClaimsActions {
    width: 180px !important;
    padding: 0px !important;
}
.tdClinicianResumeName {
    /* white-space: nowrap; */
    /* width: 100px !important; */
    padding: 10px !important;
}
.thClinicianNoClaims {
    white-space: nowrap;
    padding: 10px !important;
}
.tdClinicianNoClaims {
    padding: 10px !important;
    /* width: 50px !important; */
}
.thClaimsApprovedTotal {
    padding-right: 3px !important;
    padding-left: 3px !important;
}
.tdClaimsApprovedTotal {
    padding-right: 3px !important;
    padding-left: 3px !important;
}
.tdClaimsApprovedActions {
    padding: 0px !important;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
/* ::placeholder {
  color: #000 !important;
  opacity: 1;
}

:-ms-input-placeholder {
 color: #000 !important;
}

::-ms-input-placeholder {
 color: #000 !important;
} */
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>